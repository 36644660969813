<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('TypeBase1CCreate')"
    @edit="(id) => onEdit('TypeBase1CEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'TypeBases1C',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'TypeBases1C',
      tableCaption: 'Конфигурации',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Конфигурации',
        },
      ],
      tableHeaders: [
        {
          text: 'Наименование типа конфигурации 1С',
          alias: 'name',
          order: 'name',
        },
        { text: 'Папка чистой базы', alias: 'folder', order: 'folder' },
        {
          text: 'Наименование регистрации',
          alias: 'fullName',
          order: 'fullName',
        },
        { text: 'Релиз', alias: 'releaseNum', order: 'releaseNum' },
        {
          text: 'Скрывать при регистрации',
          alias: 'isHideOnReg',
          order: 'isHideOnReg',
        },
        {
          text: 'Платформа для запуска',
          alias: 'platformLaunch',
          order: 'platformLaunch',
        },
        {
          text: 'Платформа для обновления',
          alias: 'platformUpdate',
          order: 'platformUpdate',
        },
        { text: '', alias: 'actions', order: 'actions' },
      ],
    };
  },
};
</script>
